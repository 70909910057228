import React from 'react';
import logo from './logo.svg';
import './App.css';
import Main from './components/main';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './components/about';
import Projects from './components/projects';
import Contact from './components/contact';
import GoToTopButton from './components/GoToTopButton';
import AnimatedCursor from "react-animated-cursor"

function App() {
  return (
    <BrowserRouter>
        <GoToTopButton></GoToTopButton>
        <AnimatedCursor       
      innerSize={8}
      outerSize={35}
      innerScale={1}
      outerScale={1.7}
      outerAlpha={0}
      outerStyle={{
        border: '3px solid var(--cursor-color)'
      }}
      innerStyle={{
        backgroundColor: 'var(--cursor-color)'
      }}
      clickables={[
        'a',
        'input[type="text"]',
        'input[type="email"]',
        'input[type="number"]',
        'input[type="submit"]',
        'input[type="image"]',
        'label[for]',
        'select',
        'textarea',
        'button',
        '.link',
        {
          target: '.custom',
          options: {
            innerSize: 12,
            outerSize: 12,
            color: '255, 255, 255',
            outerAlpha: 0.3,
            innerScale: 0.7,
            outerScale: 5
          }
        }
      ]}
 />

      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/about" element={<About />} />
        <Route path="/projects" element={<Projects />} />

      </Routes>
    </BrowserRouter>
  );
}

export default App;
