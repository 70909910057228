import React from 'react'
import Header from './header'
import WebDeveloperIntro from './hero2'
import Counter from './counter'
import TechStack from './frameworks'
import Testimonials from './testimon'
import Footer from './footer'
import GoToTopButton from './GoToTopButton'
import Comptenece from './comptenece'

const main = () => {
  return (
    <div>
        <Header></Header>
        <hr/>

        <WebDeveloperIntro></WebDeveloperIntro>
        <hr/>

        <TechStack></TechStack>
        <hr/>

        {/* <Testimonials></Testimonials> */}
        <hr/>
<Comptenece></Comptenece>
        {/* <Counter></Counter> */}
        <hr/>

<Footer/>
    </div>
  )
}

export default main