import React from 'react';
import Typewriter from 'typewriter-effect';

const WebDeveloperIntro = () => {
  return (
    <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row items-center">
      <div className="md:w-1/2 mb-8 md:mb-0">
        <p className="text-gray-600 font-semibold mb-2">
          👋 Hi, Welcome There!
        </p>
        <div className='text-3xl'>
        <Typewriter
          options={{ loop: true }}
          onInit={(typewriter) => {
            typewriter.typeString("I'm a Full Stack developer")
              .callFunction(() => {
                console.log('String typed out!');
              })
              .pauseFor(2500)
              .deleteAll()
              .callFunction(() => {
                console.log('All strings were deleted');
              })
              .start();
          }}
        />
        </div>
    
        <p className="text-gray-600 mb-6">
          Dedicated web developer with a specialization in Web
          Development. Bringing expertise in web development
          to craft robust applications for comprehensive
          overviews.
          Committed to delivering high-quality solutions and
          enhancing user experiences.
        </p>
        <div className="space-x-4">
          <button className="bg-green-600 text-white px-6 py-2 rounded-md">
           <a href="/contact">Get In Touch</a> 
          </button>
          <button className="border border-green-600 text-green-600 px-6 py-2 rounded-md">
          <a href="https://portfolio.zelobrix.com/oussama_driouich_cv.pdf">View Resume</a> 
          </button>
        </div>
      </div>
      <div className="md:w-1/2">
        <div className="relative">
          <div className="absolute inset-0 transform scale-125"></div>
          <div className="relative">
            <img src="/images/git-poftolio.svg" alt="Web Developer Illustration" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebDeveloperIntro;
