import React from 'react'

const frameworks = () => {
  return (
    <div><section class="text-gray-600 body-font">
    <div class="container px-5 py-24 mx-auto">
      <div class="flex flex-col text-center w-full mb-20">
        <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">Tech Stack</h1>
      </div>
      <div class="flex flex-wrap -m-2">
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="/images/unix.png"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Unix</h2>
              <p class="text-gray-500">Bash</p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="/images/cpp.png"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">c/c++</h2>
              <p class="text-gray-500">Desktop</p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="/images/javaa.png"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Java</h2>
              <p class="text-gray-500">Desktop & Web</p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="/images/php.png"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Php</h2>
              <p class="text-gray-500">Web</p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="/images/laravel.png"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">Laravel</h2>
              <p class="text-gray-500">Back End</p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="/images/react.png"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">ReactJs</h2>
              <p class="text-gray-500">Front End</p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="/images/cpanel.png"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">CPanel</h2>
              <p class="text-gray-500">Hosting</p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-0 rounded-full mr-4" src="/images/mysqql.png"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">MySQL</h2>
              <p class="text-gray-500">DataBase</p>
            </div>
          </div>
        </div>
        <div class="p-2 lg:w-1/3 md:w-1/2 w-full">
          <div class="h-full flex items-center border-gray-200 border p-4 rounded-lg">
            <img alt="team" class="w-16 h-16 bg-gray-100 object-cover object-center flex-shrink-1 rounded-full mr-4" src="/images/database-mongo-db-icon-1954x2048-wrw32e9l.png"/>
            <div class="flex-grow">
              <h2 class="text-gray-900 title-font font-medium">MongoDB</h2>
              <p class="text-gray-500">DataBase</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section></div>
  )
}

export default frameworks