import React from 'react'
import Header from './header'
import Footer from './footer'

const About = () => {
  return (
    <div>
      <Header></Header>
     <div>
     <section class="text-gray-600 body-font">
  <div class="container mx-auto flex px-5 py-24 md:flex-row flex-col items-center">
    <div class="lg:max-w-sm lg:w-full md:w-1/2 w-5/6 mb-10 md:mb-0">
      <img class="object-cover object-center rounded" alt="hero" src="/images/PHOTO CV copy.png"/>
    </div>
    <div class="lg:flex-grow md:w-1/2 lg:pl-24 md:pl-16 flex flex-col md:items-start md:text-left items-center ">
      <h1 class="title-font sm:text-3xl text-2xl mb-4 font-medium text-black">Discover Oussama Driouich:
        <br class="hidden lg:inline-block"/> Passion, Precision, Progress</h1>
      <p class="mb-8 leading-relaxed"> 
I'm Oussama Driouich, 22 years old, Full Stack Web Developer ,I hold a Bachelor's Degree in Computer Science. with a strong background in PHP,Laravel and Javascript .
I have over almost a year of experience at private IT companies. Also as a freelancer.
I'm positive, open-minded and determined. I'm always looking for personal and professional self-improvement, looking for greater and bigger challenges, and for great opportunities of sharing and learning with the best.

</p>
<div className="space-x-4">
          <button className="bg-green-600 text-white px-6 py-2 rounded-md">
           <a href="/contact">Get In Touch</a> 
          </button>
          <button className="border border-green-600 text-green-600 px-6 py-2 rounded-md">
          <a href="https://portfolio.zelobrix.com/oussama_driouich_cv.pdf">View Resume</a> 
          </button>
        </div>
    </div>
  </div>
</section>    
  </div>
      <hr/>
      {/* _____________________________________        prt2    __________________________________*/}
    <div className='justify text-center' >
    <p className="text-balck text-3xl">        Education & Experice
    </p>
    <div className="text-right">
            {/* <img width={"100px"} height={"100px"} src="/images/chatgpt.jpeg" /> */}
          </div>
      <div className=' text-left'>
        
          
          <section class="text-gray-600 body-font ">
         
        
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
        <div class="flex flex-wrap w-full">
          <div class="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-200 inline-flex items-center justify-center text-white relative z-10">
               <img src="/images/icons8-school-64.png" alt=""/>
              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Bachelor's Degree in Computer Science</h2>
                <p class="leading-relaxed">Faculty of ibno zohr Agadir</p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-200 inline-flex items-center justify-center text-white relative z-10">
              <img width={"38px"} src="/images/icons8-coding-48.png" alt=""/>

              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">Internship at Marketing&Digital Agency</h2>
                <p class="leading-relaxed">Expert-Sud Agency,Agadir</p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-200 inline-flex items-center justify-center text-white relative z-10">
              <img src="/images/icons8-school-64.png" alt=""/>

              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">DEUG in computer science</h2>
                <p class="leading-relaxed">Faculty of ibno zohr Agadir</p>
              </div>
            </div>
            <div class="flex relative pb-12">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full bg-green-200 inline-flex items-center justify-center text-white relative z-10">
              <img src="/images/icons8-school-64.png" alt=""/>

              </div>
              <div class="flex-grow pl-4">
                <h2 class="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">High School Degree in Math A.</h2>
                <p class="leading-relaxed">School of Mohammed Derfoufi,Agadir</p>
              </div>
            </div>
           
          
          </div>
        </div>
        </div>
          </section>
      </div>
      {/* -------------------------------- experice -----------------------------*/}
  
    </div>

    <Footer></Footer>
    </div>
  )
}

export default About