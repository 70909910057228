import React from 'react'
import Header from './header'
import Footer from './footer'

const Projects = () => {
  return (
    <div>
      <Header></Header>
      <section class="text-gray-600 body-font">
  <div class="container px-5 py-24 mx-auto">
    <div class="flex flex-col text-center w-full mb-20">
      <h1 class="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">My Projects: Transforming Ideas into Reality</h1>
      <p class="lg:w-2/3 mx-auto leading-relaxed text-base">From Concept to Completion</p>
    </div>
    <div class="flex flex-wrap -m-4">
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="/images/prtt1.webp"/>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">NextJs,NodeJs</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Beauty Sahara</h1>
            <p class="leading-relaxed">Discover the Purity of Nature
              Shop Our Premium Organic Products
              Nourish Your Body and Soul</p>
                        </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="/images/west.webp"/>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">Wordpress,Php,ReactJs</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Westyal Perfume</h1>
            <p class="leading-relaxed">Indulge in Luxurious Fragrances
                Explore Our Exquisite Collection
                Elevate Your Senses with Every Scent</p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="/images/MAST.webp"/>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">Laravel,React</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Master Skills</h1>
            <p class="leading-relaxed">"Unlock Your Potential with Expert Instruction
      Join Our Elite Master Classes Today
      Transform Your Skills with Proven Methods</p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="/images/fizak.webp"/>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">NextJs,Laravel</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">Fizak</h1>
            <p class="leading-relaxed">Explore Our Wide Range of Quality Products
              Efficient Global Shipping Solutions
              Connecting You to the World with Ease</p>
          </div>
        </div>
      </div>
      <div class="lg:w-1/3 sm:w-1/2 p-4">
        <div class="flex relative">
          <img alt="gallery" class="absolute inset-0 w-full h-full object-cover object-center" src="/images/store-mern.png"/>
          <div class="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100">
            <h2 class="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">NodeJs,Express,React,MongoDB</h2>
            <h1 class="title-font text-lg font-medium text-gray-900 mb-3">ShoppeLux</h1>
            <p class="leading-relaxed">Step into Style and Comfort
              Discover Our Premium Shoe Collection
              Perfect Fit for Every Occasion</p>
          </div>
        </div>
      </div>
    
    </div>
  </div>
</section>

<Footer></Footer>
</div>
  )
}

export default Projects